import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Alert, Tabs, Tab, Box, Button, Container, LinearProgress, TextField, Typography, List, ListItem } from '@mui/material';

import Uploader from './Upload';
import ImageGallery from './ImageGallery'

function App() {

  const [project, setProject] = useState('');
  const [temp, setTemp] = useState('mundm');

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handler = () => {
    if(temp){
      setProject(temp);
    }
  };


  if(project){
    return (
      <Box sx={{mt:2}}>
        <Typography variant="h4" align="center" gutterBottom>
          Fotos teilen
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Upload" />
            <Tab label="Gallery" />          
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Uploader project={project} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ImageGallery project={project} />
        </CustomTabPanel>
        
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Unsere Fotos
      </Typography>
      <TextField value={temp} onChange={(evt)=>setTemp(evt.target.value)} label="Zugangs-Code" default="mundm" />
      <Alert sx={{mt:2,mb:2}} severity="info">Für Miro&Monis Hochzeit lautet der Zugangscode: "mundm"</Alert>
      <Button variant="outlined" onClick={handler}>Los geht's</Button>
    </Container>
  );
}

export default App;




function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}