import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Container, LinearProgress, TextField, Typography, List, ListItem } from '@mui/material';

function BulkImageUploader({project}) {
  const [name, setName] = useState('');
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true,
  });

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const uploadFiles = async () => {
    if (!name) {
      alert('Bitte deinen Namen angeben.');
      return;
    }
    if (files.length === 0) {
      alert('Bitte mindestens ein Bild auswählen.');
      return;
    }
    setIsUploading(true);

    const newUploadProgress = {};

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      try {
        // Pre-Signed URL von der Lambda-Funktion abrufen
        const response = await axios.post('https://prbcuybtck.execute-api.eu-central-1.amazonaws.com/prod/upload', {
          name: name,
          filename: file.name,
          contentType: file.type,
          project
        });

        const { url } = response.data;

        // Bild direkt zu S3 hochladen mit Fortschrittsanzeige
        await axios.put(url, file, {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            newUploadProgress[file.name] = percentCompleted;
            setUploadProgress({ ...newUploadProgress });
          },
        });

        console.log(`${file.name} erfolgreich hochgeladen.`);
      } catch (error) {
        console.error(`Fehler beim Hochladen von ${file.name}: `, error);
        newUploadProgress[file.name] = 'Fehler';
        setUploadProgress({ ...newUploadProgress });
      }
    }
  };

  return (
    <Container maxWidth="sm">
      {!isUploading && <Box>
          <Box sx={{ mt: 4 }}>
            <TextField
              fullWidth
              label="Dein Name"
              value={name}
              onChange={handleNameChange}
              variant="outlined"
            />
          </Box>
          <Box
            {...getRootProps()}
            sx={{
              border: '2px dashed #888',
              padding: '20px',
              textAlign: 'center',
              marginTop: '20px',
              cursor: 'pointer',
              height: '30vh',
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Ziehe die Bilder hierher...</Typography>
            ) : (
              <Typography>Ziehe Bilder hierher oder klicke, um Bilder auszuwählen</Typography>
            )}
          </Box>
      </Box>}
      <Button variant="contained" color="primary" onClick={uploadFiles} sx={{ mt: 2 }} disabled={isUploading}>
        Jetzt hochladen
      </Button>
      {files.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Ausgewählte Bilder:</Typography>
          <List>
            {files.map((file) => (
              <ListItem key={file.path || file.name}>
                <Typography sx={{ flexGrow: 1 }}>{file.name}</Typography>
                {uploadProgress[file.name] && uploadProgress[file.name] !== 'Fehler' && (
                  <Box sx={{ width: '100%', mx: 1 }}>
                    <LinearProgress variant="determinate" value={uploadProgress[file.name]} />
                  </Box>
                )}
                {uploadProgress[file.name] === 'Fehler' && (
                  <Typography color="error">Fehler beim Upload</Typography>
                )}
                {uploadProgress[file.name]}%
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Container>
  );
}

export default BulkImageUploader;
