import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Button, Container, CircularProgress, Typography } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import videoImage from './download.png';

function ImageGallery({ project }) {
  const projectKey = project;
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isTruncated, setIsTruncated] = useState(false);
  const [nextContinuationToken, setNextContinuationToken] = useState(null);
  const loadMoreButtonRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screen size

  const fetchImages = async (continuationToken = null) => {
    try {
      const response = await axios.post('https://prbcuybtck.execute-api.eu-central-1.amazonaws.com/prod/gallery', {
        projectKey,
        continuationToken,
      });
      const { groupedImages, isTruncated, nextContinuationToken } = response.data;

      // Avoid duplicates by filtering based on image names
      setImages((prevImages) => {
        const newImages = groupedImages.filter(
          (newImage) => !prevImages.some((prevImage) => prevImage.name === newImage.name)
        );
        return [...prevImages, ...newImages];
      });

      setIsTruncated(isTruncated);
      setNextContinuationToken(nextContinuationToken);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching images:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
  }, [projectKey]);

  const loadMoreImages = () => {
    if (isTruncated) {
      fetchImages(nextContinuationToken);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreImages(); // Trigger the loading of more images
        }
      },
      { threshold: 1.0 } // Trigger when the button is fully visible
    );

    if (loadMoreButtonRef.current) {
      observer.observe(loadMoreButtonRef.current);
    }

    return () => {
      if (loadMoreButtonRef.current) {
        observer.unobserve(loadMoreButtonRef.current);
      }
    };
  }, [isTruncated, nextContinuationToken]); // Only trigger when there's more to load

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>

      <ImageList sx={{ width: '100%', height: 'auto' }} cols={isMobile ? 2 : 4} gap={16}>
        {images.map((image) => (
          <ImageListItem key={image.name}>
            {image.name.match(/(mov|mp4)$/) !== null ? (
              <img src={videoImage} alt={image.name} loading="lazy" style={{ width: '100%', minHeight: '200px' }} />
            ) : (
              <img src={image?.thumbUrl600px} alt={image.name} loading="lazy" style={{ width: '100%', minHeight: '200px' }} />
            )}
            <ImageListItemBar
              title={image.name}
              actionIcon={
                <Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    href={image.fullResUrl}
                    download
                    size="small"
                    target="_blank"
                    sx={{mr: 1}}
                  >
                    Download
                  </Button>
                </Box>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>

      {isTruncated && (
        <Box textAlign="center" mt={4}>
          {/* Ref for IntersectionObserver */}
          <Button ref={loadMoreButtonRef} variant="contained">
            Mehr Bilder laden
          </Button>
        </Box>
      )}
    </Container>
  );
}

export default ImageGallery;
